html,
body {
    background-color: #000000;
    color: white;
}


.container {
    padding: 10px;
    padding-top: 40px;
    padding-bottom: 60px;
    font-family: Arial, sans-serif;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.128);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-optical-sizing: auto;
    font-style: normal;

}

h1 {
    margin: 0px;
    font-size: 70px;
}

p {
    color: #969696;
    font-size: 17px;
}

label {
    margin-bottom: 10px;
    font-size: 15px;
}

.subContainer {
    margin-top: 30px;
}

.FormInputDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
}

input[type="text"],
input[type="number"] {
    padding: 20px;
    margin-bottom: 10px;
    border: 0px;
    border-radius: 35px;
    font-size: 16px;
    background-color: white;
    box-sizing: border-box;
    border: 2px solid;
}

input[type="text"]:focus,
input[type="number"]:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}


input.tableInput {
    border: 2px solid silver;
    max-width: 300px;
}


h2 {
    font-size: 45px;
    color: black;
}

.containerTwo {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;
    width: 100%;
}

/* Flex container styles */
.flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    max-width: 1000px;
    width: 100%;
    box-sizing: border-box;
}

/* Flex row styles */
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
    margin-top: 30px;
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
}

.flex-row>* {
    margin: 10px;
}

#removeBtn {
    align-self: flex-end;
}

.FormBtnDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 385px;
}

.FormBtnDiv>* {
    flex: 1 1 auto;
    margin-top: 20px;
    width: 100%;
}

#addGroupBtn {
    padding: 20px 40px;
    background-color: #0173ed;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

#addGroupBtn:hover {
    background-color: #007bff;
}

#calculateBtn {
    padding: 20px 40px;
    background: transparent;
    color: #0173ed;
    border: 2px solid #0173ed;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 20px;
}

#calculateBtn:hover {
    background-color: #007bff;
    color: white;
}


#removeBtn {
    padding: 20px 40px;
    background-color: #0173ed;
    color: #fff;
    border: none;
    border-radius: 35px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
}

#removeBtn:hover {
    background-color: #007bff;
}


div.containerThree {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 80px;
    padding-top: 80px;
}

/* Dynamic table style*/
.containerTable {
    max-width: 100%;
    overflow-x: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-top: 20px;
    padding: 20px;
    font-size: 18px;
}




.header,
.row {
    display: flex;
    padding: 15px 10px;
    border: 1px solid #dee2e6;
    align-items: center;
    margin-top: 5px;
}


.header {
    font-weight: bold;
}

.header div,
.row div {
    flex: 1;
    padding: 0 10px;
    text-align: left;
}

.cell {
    padding: 12px 15px;

}



.containerFour {
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subContainerFour {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
}

#clearBtn {
    margin-top: 15px;
    padding: 20px 40px;
    background: transparent;
    color: #0173ed;
    border: 2px solid #0173ed;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

#clearBtn:hover {
    background-color: #007bff;
    color: white;
}


#exportToExcelBtn {
    padding: 20px 40px;
    background-color: #0173ed;
    color: #fff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

#exportToExcelBtn:hover {
    background-color: #007bff;
    color: white;
}




@media(max-width: 1100px) {
    div.responsiveDiv {
        display: flex;
        flex-direction: row;
    }

    div.header {
        flex-direction: column;
    }

    div.row {
        flex-direction: column;
    }

    div.cell {
        width: 100%;
        border-bottom: 1px solid white;
    }


}

@media(max-width: 861px) {

    .header,
    .row {
        font-size: 15px;
    }
}

@media(max-width: 730px) {
    .flex-row {
        justify-content: center;
    }
}

@media(max-width: 666px) {}

@media(max-width: 575px) {
    .flex-row {
        flex-direction: column;
    }
}